/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AnchorLink from '@components/AnchorLink';
import ButtonNew from '@components/ButtonNew';
import colon from './img/colon.svg';
import expiredColon from './img/expired-colon.svg';
import s from './Countdown.module.scss';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaURL: PropTypes.string,
  finishedCtaLabel: PropTypes.string,
  finishedCtaURL: PropTypes.string,
  datetimeValue: PropTypes.string.isRequired,
};

const defaultProps = {
  title: null,
  description: null,
  ctaLabel: null,
  ctaURL: null,
  finishedCtaLabel: null,
  finishedCtaURL: null,
};

const Countdown = ({
  title,
  description,
  datetimeValue,
  ctaLabel,
  ctaURL,
  finishedCtaLabel,
  finishedCtaURL,
}) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [showDays, setShowDays] = useState(true);

  useEffect(() => {
    let intervalId;
    const calculateTime = () => {
      const currentDateTime = new Date();
      const targetDateTime = new Date(datetimeValue);
      const timeDifference = targetDateTime - currentDateTime;
      if (timeDifference <= 0) {
        clearInterval(intervalId);
        setCountdownEnded(true);
        return;
      }
      const differenceInSeconds = Math.floor(timeDifference / 1000);
      const differenceInMinutes = Math.floor(differenceInSeconds / 60);
      const differenceInHours = Math.floor(differenceInMinutes / 60);
      const differenceInDays = Math.floor(differenceInHours / 24);

      const days = differenceInDays.toString().padStart(2, '0');
      const hours = (differenceInHours % 24).toString().padStart(2, '0');
      const minutes = (differenceInMinutes % 60).toString().padStart(2, '0');
      const seconds = (differenceInSeconds % 60).toString().padStart(2, '0');
      setTimeLeft({
        days,
        hours,
        minutes,
        seconds,
      });
    };
    intervalId = setInterval(() => {
      calculateTime();
    }, 1000);
    const currentDateTime = new Date();
    const targetDateTime = new Date(datetimeValue);
    const timeDifference = targetDateTime - currentDateTime;
    const differenceInSeconds = Math.floor(timeDifference / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);
    if (differenceInDays <= 0) {
      setShowDays(false);
    }
    calculateTime();
    return () => {
      clearInterval(intervalId);
    };
  }, [datetimeValue]);

  return (
    <div className={s.root}>
      {title && <div className={s.title}>{title}</div>}
      {description && <div className={s.description}>{description}</div>}
      {!countdownEnded && (
        <div className={s.countdownTimer}>
          {showDays && (
            <>
              <div className={cx(s.unit, timeLeft.days === '00' ? s.expired : '')}>
                <div className={s.digit}>{timeLeft.days}</div>
                <div className={s.unitName}>Days</div>
              </div>
              <img src={timeLeft.days === '00' ? expiredColon : colon} alt="colon" />
            </>
          )}
          <div className={cx(s.unit)}>
            <div className={s.digit}>{timeLeft.hours}</div>
            <div className={s.unitName}>Hours</div>
          </div>
          <img src={colon} alt="colon" />
          <div className={cx(s.unit)}>
            <div className={s.digit}>{timeLeft.minutes}</div>
            <div className={s.unitName}>Minutes</div>
          </div>
          <img src={colon} alt="colon" />
          <div className={cx(s.unit)}>
            <div className={s.digit}>{timeLeft.seconds}</div>
            <div className={s.unitName}>Seconds</div>
          </div>
        </div>
      )}
      {!countdownEnded && ctaLabel && ctaURL && (
        <div className={s.cta}>
          <AnchorLink href={ctaURL}>
            <ButtonNew theme="modern" color="purple" label={ctaLabel} />
          </AnchorLink>
        </div>
      )}
      {countdownEnded && finishedCtaLabel && finishedCtaURL && (
        <AnchorLink href={finishedCtaURL}>
          <ButtonNew theme="modern" color="purple" label={finishedCtaLabel} />
        </AnchorLink>
      )}
    </div>
  );
};

Countdown.propTypes = propTypes;
Countdown.defaultProps = defaultProps;
export default Countdown;
