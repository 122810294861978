import React from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from '@contexts/user-context';
import useCountry from '@hooks/useCountry';
import HeadMeta from '@components/HeadMeta';
import Layout from '@components/MyLayout';
import SegmentPage from '@components/SegmentPage';
import ShopRow from '@components/ShopRow';
import Hero from '@components/DynamicComponents/HomeHero';
import Countdown from '@components/Countdown';
import MultiTilePromo from '@components/DynamicComponents/MultiTilePromo';
import FlexibleContainer from '@components/DynamicComponents/FlexibleContainer';
import FeaturedQuizList from '@/src/components/DynamicComponents/FeaturedQuizList';
import UserIdentityChecklist from '@components/DynamicComponents/UserIdentityChecklist';
import FlexibleCarousel from '@components/DynamicComponents/FlexibleCarousel';
import FullWidthBanner from '@components/DynamicComponents/FullWidthBanner';
import { HOMEPAGE_COMPONENT_TYPES as COMPONENTS } from '@constants/index';
import s from './HomeDynamic.module.scss';

const propTypes = {
  shop: PropTypes.shape({}),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  pageData: PropTypes.shape({
    contentSlots: PropTypes.array,
    searchTitle: PropTypes.string,
    searchDescription: PropTypes.string,
    openGraphTitle: PropTypes.string,
    openGraphDescription: PropTypes.string,
    entryTitle: PropTypes.string,
    analyticsName: PropTypes.string,
    externalId: PropTypes.string,
    contentfulId: PropTypes.string,
    contentTypeId: PropTypes.string,
    revision: PropTypes.number,
    openGraphImage: PropTypes.shape({}),
    twitterCardImage: PropTypes.shape({}),
    theme: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  patronusData: PropTypes.array,
};

const defaultProps = {
  shop: null,
  metaTitle: null,
  metaDescription: null,
  pageData: {
    entryTitle: '',
    contentSlots: [],
  },
  patronusData: null,
};

const HomeDynamic = ({ metaTitle, metaDescription, pageData, patronusData }) => {
  const { profile, loadingProfile, isLoggedIn } = useUserContext();
  const { country, loading: loadingCountry } = useCountry('');

  const pageName = 'Wizarding World Home';
  const { contentSlots } = pageData;
  const geotargetCheckingEnabled = contentSlots.some(
    (content) => content.contentTypeId === COMPONENTS.GEOTARGETED_CONTENT,
  );

  let rowCount = 1;

  const renderClassNames = (index, length) => {
    return [
      s.row,
      index === 0 ? s.rowFirst : s[`row${rowCount % 6}`],
      index === length - 1 ? s.rowLast : '',
    ];
  };

  const renderRow = (content, index) => {
    if (index !== 0 && content.contentTypeId !== COMPONENTS.FULL_WIDTH_PROMO) {
      rowCount += 1;
    }

    const analyticsParams = {
      verticalIndex: index + 1, // nav bar is index 0
      rootContentfulId: content?.contentfulId,
      rootContentTypeId: content?.contentTypeId,
      rootEntryTitle: content?.entryTitle,
    };

    switch (content.contentTypeId) {
      case COMPONENTS.PRIMARY_HERO: {
        return <Hero {...content} analyticsParams={analyticsParams} />;
      }
      case COMPONENTS.MULTI_TILE_PROMO: {
        return <MultiTilePromo {...content} analyticsParams={analyticsParams} />;
      }
      case COMPONENTS.FLEXIBLE_CONTAINER: {
        return <FlexibleContainer {...content} analyticsParams={analyticsParams} />;
      }
      case COMPONENTS.COUNTDOWN: {
        return <Countdown {...content} />;
      }
      case COMPONENTS.FEATURED_QUIZ_LIST: {
        return <FeaturedQuizList {...content} analyticsParams={analyticsParams} />;
      }
      case COMPONENTS.USER_IDENTITY_CHECKLIST: {
        return (
          <UserIdentityChecklist
            patronusData={patronusData}
            analyticsParams={analyticsParams}
            header={content?.header}
            housePercentages={{
              gryffindor: content?.gryffindorPercentage || 32,
              hufflepuff: content?.hufflepuffPercentage || 40,
              ravenclaw: content?.ravenclawPercentage || 12,
              slytherin: content?.slytherinPercentage || 16,
            }}
          />
        );
      }
      case COMPONENTS.FLEXIBLE_CAROUSEL: {
        return (
          <FlexibleCarousel
            {...content}
            rowCount={(rowCount - 1) % 6}
            analyticsParams={analyticsParams}
          />
        );
      }
      case COMPONENTS.FULL_WIDTH_PROMO: {
        return <FullWidthBanner {...content} analyticsParams={analyticsParams} />;
      }
      case COMPONENTS.SHOP_ROW: {
        return <ShopRow shopData={content} pageName={pageName} location="home" />;
      }
      default:
        return null;
    }
  };

  const shouldHideContent = (content) => {
    let conditions = [false];
    switch (content.contentTypeId) {
      case COMPONENTS.USER_IDENTITY_CHECKLIST:
        conditions = [
          !loadingProfile,
          isLoggedIn,
          content.hideWhenComplete,
          profile?.hogwartsHouse,
          profile?.patronusName,
          profile?.wand?.core,
          profile?.avatar?.avatarId,
          profile?.marketingOptInWW,
        ];
        break;
      case COMPONENTS.SHOP_ROW:
        // eslint-disable-next-line no-case-declarations
        const condition = !isLoggedIn || profile?.underage;
        conditions = [condition];
        break;
      case COMPONENTS.PRIMARY_HERO: {
        const targetedHeroExists = contentSlots.some((contentSlot) => {
          return (
            contentSlot.contentTypeId === COMPONENTS.GEOTARGETED_CONTENT &&
            contentSlot.content.contentTypeId === COMPONENTS.PRIMARY_HERO &&
            contentSlot?.countries?.includes(country)
          );
        });
        conditions = [geotargetCheckingEnabled, loadingCountry || targetedHeroExists];
        break;
      }
      case COMPONENTS.GEOTARGETED_CONTENT:
        conditions = [loadingCountry || !content?.countries?.includes(country)];
        break;
      /* istanbul ignore next */
      default:
        break;
    }
    return conditions.every(Boolean);
  };

  const processContentSlots = (rawContentSlots) => {
    // Filter out any content slots that should be hidden
    const filteredContentSlots = rawContentSlots.filter((content) => !shouldHideContent(content));

    // Move visible geotargeted content out of wrapper and into root level of the content slot
    const flattenedContentSlots = filteredContentSlots.reduce((acc, content) => {
      if (content.contentTypeId === COMPONENTS.GEOTARGETED_CONTENT) {
        return [...acc, content.content];
      }
      return [...acc, content];
    }, []);

    // Ensure only one hero is present on the page
    const deDuplicatedContentSlots = flattenedContentSlots.reduce((acc, content) => {
      if (
        content.contentTypeId === COMPONENTS.PRIMARY_HERO &&
        acc.some((c) => c.contentTypeId === COMPONENTS.PRIMARY_HERO)
      ) {
        return [...acc];
      }
      return [...acc, content];
    }, []);

    // Ensure a placeholder hero is always present.
    // This adds a fallback state for missing content, and ensures
    // the hero space is preserved while country loads for geotargeted heroes.
    if (
      !deDuplicatedContentSlots.some((content) => content.contentTypeId === COMPONENTS.PRIMARY_HERO)
    ) {
      deDuplicatedContentSlots.unshift({
        contentTypeId: COMPONENTS.PRIMARY_HERO,
        contentfulId: 'placeholder-hero',
        delayReveal: true,
      });
    }

    return deDuplicatedContentSlots;
  };

  const processedContentSlots = processContentSlots(contentSlots);

  return (
    <Layout location={pageName}>
      <HeadMeta canonicalUrl={'/'} title={metaTitle} description={metaDescription} />
      <SegmentPage key={pageName} pageName={pageName} />
      <div className={s.root}>
        {processedContentSlots.map((content, index) => {
          return (
            <div
              key={content.contentfulId}
              className={[...renderClassNames(index, processedContentSlots.length)].join(' ')}
              data-testid={`${content.contentTypeId}-${content.contentfulId}`}
            >
              {/* Used to link directly to a row position from an external source */}
              <span
                id={`homepage-row-${index + 1}`}
                data-testid={`homepage-row-${index + 1}`}
                className={s.anchorPosition}
              />
              <div
                className={[
                  s.rowComponent,
                  /* istanbul ignore next */
                  content?.contentTypeId ? s[content.contentTypeId] : '',
                ].join(' ')}
              >
                {renderRow(content, index)}
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

HomeDynamic.propTypes = propTypes;
HomeDynamic.defaultProps = defaultProps;
export default HomeDynamic;
