import React from 'react';
import PropTypes from 'prop-types';
import logger from '@utils/logger';
import { getPrebuiltPageRevalidateTime } from '@utils/staticSiteGeneration';
import { getRecentQuizzes } from '@utils/homepage';
import {
  fetchAllPagedContent,
  fetchContent,
  pruneRelated,
  withNavigation,
} from '@utils/contentfulHelpers';
import HomeDynamic from '../src/pages/HomeDynamic';

const propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.shape({
        heroContent: PropTypes.array,
        news: PropTypes.array,
        feature: PropTypes.array,
        primaryCarousel: PropTypes.array,
      }),
    }),
  ),
  errors: PropTypes.shape({}),
  navData: PropTypes.shape({}),
  footerData: PropTypes.shape({}),
};

const defaultProps = {
  content: [],
  errors: {},
  navData: null,
  footerData: null,
};

const HomePage = ({ content, errors }) => {
  if (!content.length) return null;
  const [item] = content;
  const { body } = item;
  const { contentSlots = [], searchTitle, searchDescription, patronusData } = body || {};

  return (
    <HomeDynamic
      errors={errors}
      metaTitle={searchTitle}
      metaDescription={searchDescription}
      patronusData={patronusData}
      pageData={{ contentSlots }}
    />
  );
};

export async function getStaticProps() {
  const [contentArray, errors] = await fetchContent({
    contentTypes: ['homePage'],
  });
  const homePage = contentArray?.[0];
  logger(`Page: /`, homePage, errors);

  // Throw an error if we failed to fetch content so we
  // fall back to the last successful statically generated version
  if (!homePage || errors) {
    throw new Error(`Failed to fetch home page data: ${JSON.stringify(errors)}`);
  }

  const [patronusData] = await fetchAllPagedContent({ contentTypes: ['patronus'] });
  const patronusArray = patronusData.map((item) => item.body);

  await getRecentQuizzes(homePage.body.contentSlots);

  const content = [
    {
      ...homePage,
      body: {
        ...homePage.body,
        contentSlots: homePage.body.contentSlots || [],
        heroContent: pruneRelated(homePage.body.heroContent),
        primaryCarousel: pruneRelated(homePage.body.primaryCarousel),
        news: pruneRelated(homePage.body.news),
        feature: pruneRelated(homePage.body.feature),
        quiz: pruneRelated(homePage.body.quiz),
        shop: homePage.body.shop?.[0] || null,
        patronusData: patronusArray || null,
      },
    },
  ];

  const props = await withNavigation({
    errors,
    content,
  });

  const revalidate = getPrebuiltPageRevalidateTime(
    'home',
    Number(process.env.HUB_REVALIDATION_TIMEOUT) || 30,
  );

  return {
    props,
    revalidate,
  };
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;
export default HomePage;
