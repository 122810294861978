import React from 'react';
import PropTypes from 'prop-types';
import ContentTile from '@components/DynamicComponents/ContentTile';
import s from './MultiTilePromo.module.scss';

const propTypes = {
  contentSlots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  header: PropTypes.string,
  subheader: PropTypes.string,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
  }),
};

const defaultProps = {
  header: '',
  subheader: '',
  analyticsParams: {},
};

const MultiTilePromo = ({ contentSlots, header, subheader, analyticsParams }) => {
  const { verticalIndex } = analyticsParams;
  return (
    <div className={s.root}>
      {(header || subheader) && (
        <div className={s.heading}>
          {header && <div className={s.header}>{header}</div>}
          {subheader && <div className={s.subHeader}>{subheader}</div>}
        </div>
      )}
      <div className={s.promosContainer}>
        {contentSlots.map((slot, index) => (
          <ContentTile
            content={slot}
            key={slot.externalId}
            analyticsParams={{
              horizontalIndex: index,
              verticalIndex,
            }}
          />
        ))}
      </div>
    </div>
  );
};

MultiTilePromo.propTypes = propTypes;
MultiTilePromo.defaultProps = defaultProps;

export default MultiTilePromo;
