import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ButtonNew from '@/src/components/ButtonNew';
import AnchorLink from '@/src/components/AnchorLink';
import RenderAnimation from '@/src/components/RenderAnimation';
import explosionBg from '../../img/incomplete-explosion.png';
import completedAnimation from '../../../../../animations/identity_checklist_completed_item.json';
import s from './ChecklistItem.module.scss';

const propTypes = {
  third: PropTypes.bool,
  completed: PropTypes.bool,
  cta: PropTypes.string.isRequired,
  ctaColor: PropTypes.string,
  ctaLinkUrl: PropTypes.string.isRequired,
  StartImage: PropTypes.func.isRequired,
  CompletedImage: PropTypes.func.isRequired,
  completedEyebrow: PropTypes.string.isRequired,
  completedHeading: PropTypes.string,
  completedLinkText: PropTypes.string,
  completedLinkUrl: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isInView: PropTypes.bool.isRequired,
  childNumber: PropTypes.number.isRequired,
};

const defaultProps = {
  third: false,
  completed: false,
  ctaColor: 'gold',
  completedHeading: '',
  completedLinkText: 'Learn More',
  completedLinkUrl: null,
  className: '',
  onClick: /* istanbul ignore next */ () => {},
};

const INITIAL_COMPLETE_ANIMATION_DELAY = 500;
const TIME_BETWEEN_CHILD_ANIMATIONS = 150;

const ChecklistItem = ({
  third,
  completed,
  cta,
  ctaColor,
  ctaLinkUrl,
  StartImage,
  CompletedImage,
  completedEyebrow,
  completedHeading,
  completedLinkText,
  completedLinkUrl,
  className,
  onClick,
  isInView,
  childNumber,
}) => {
  const [hideAnimation, setHideAnimation] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [revealCompletedDetails, setRevealCompletedDetails] = useState(false);

  const router = useRouter();

  // timers for showing lottie animation/hiding it and revealing details for completed state
  useEffect(() => {
    let removeLottieTimeout;
    let lottieDelayTimeout;
    let revealDelayTimeout;

    if (completed && isInView) {
      const lottieDelay =
        TIME_BETWEEN_CHILD_ANIMATIONS * childNumber + INITIAL_COMPLETE_ANIMATION_DELAY;

      lottieDelayTimeout = setTimeout(() => {
        setShowAnimation(true);
        removeLottieTimeout = setTimeout(() => {
          setHideAnimation(true);
        }, 1000);
        revealDelayTimeout = setTimeout(() => {
          setRevealCompletedDetails(true);
        }, 250);
      }, lottieDelay);
    }

    return () => {
      clearTimeout(removeLottieTimeout);
      clearTimeout(lottieDelayTimeout);
      clearTimeout(revealDelayTimeout);
    };
  }, [isInView, completed, childNumber]);

  return (
    <div
      className={[s.container, third ? s.third : '', completed ? s.completed : '', className].join(
        ' ',
      )}
      style={{
        '--explosion-bg': `url(${explosionBg})`,
      }}
      onClick={() => onClick()}
      role="button"
      tabIndex={0}
      data-testid="checklist-item"
      onKeyDown={
        /* istanbul ignore next */ (e) => {
          if (e.keyCode === 13) onClick();
        }
      }
    >
      {!completed && (
        <>
          <div
            className={s.startImage}
            onClick={() => router.push(ctaLinkUrl)}
            onKeyDown={
              /* istanbul ignore next */ (e) => {
                if (e.keyCode === 13) {
                  router.push(ctaLinkUrl);
                }
              }
            }
            role="button"
            tabIndex={0}
            aria-label={cta}
            data-testid="start-image"
          >
            <StartImage />
          </div>
          <div className={s.startCta}>
            <AnchorLink href={ctaLinkUrl} className={s.startingCtaLink}>
              <ButtonNew
                label={cta}
                level="primary"
                theme="modern"
                color={ctaColor}
                className={s.buttonOverride}
                isGradient
                size="small"
              />
            </AnchorLink>
          </div>
        </>
      )}
      {completed && (
        <>
          {isInView && showAnimation && !hideAnimation && (
            <RenderAnimation
              animationData={completedAnimation}
              loop={false}
              ariaLabel="Completed Item"
              className={s.completedAnimation}
            />
          )}
          <div className={s.completedImage}>
            <CompletedImage />
          </div>
          <div className={[s.completedDetails, revealCompletedDetails ? s.reveal : ''].join(' ')}>
            <div className={s.completedEyebrow} data-testid="completed-eyebrow">
              {completedEyebrow}
            </div>
            <div className={s.completedHeading}>
              <div data-testid="completed-heading" className={s.innerHeading}>
                {completedHeading}
              </div>
            </div>
            {completedLinkUrl && completedLinkText && (
              <AnchorLink
                href={completedLinkUrl}
                className={s.learnMore}
                data-testid="completed-link"
              >
                {completedLinkText}
              </AnchorLink>
            )}
          </div>
        </>
      )}
    </div>
  );
};

ChecklistItem.propTypes = propTypes;
ChecklistItem.defaultProps = defaultProps;
export default ChecklistItem;
