import React from 'react';
import PropTypes from 'prop-types';
import s from './CloudRevealAnimation.module.scss';
import cloudsL from './imgs/clouds-left.png';
import cloudsR from './imgs/clouds-right.png';

const propTypes = {
  children: PropTypes.node,
  inView: PropTypes.bool,
};

const defaultProps = {
  children: <></>,
  inView: false,
};

const CloudRevealAnimation = ({ children, inView }) => {
  return (
    <div
      style={{
        '--clouds-l': `url(${cloudsL})`,
        '--clouds-r': `url(${cloudsR})`,
      }}
      className={[s.root, inView ? s.animate : ''].join(' ')}
    >
      {children}
      <div className={[s.cloud, s.cloudLeft].join(' ')} />
      <div className={[s.cloud, s.cloudRight].join(' ')} />
      <div className={s.overlay} />
    </div>
  );
};

CloudRevealAnimation.propTypes = propTypes;
CloudRevealAnimation.defaultProps = defaultProps;
export default CloudRevealAnimation;
